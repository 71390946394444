<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title
            >Connector Block Configuration -
            {{ block ? block.ref : "" }}</v-toolbar-title
          >
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="showDeleteDialog('delete')" v-on="on">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete this connector block</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-list subheader flat>
          <v-subheader>
            <v-row>
              <v-col cols="12" md="4" class="font-weight-bold body-1">
                Pin Number
              </v-col>
              <v-col cols="12" md="4" class="font-weight-bold body-1">
                Connector's Pin Name
              </v-col>
              <v-col cols="12" md="4" class="font-weight-bold body-1">
                TPCB Net
              </v-col>
            </v-row>
          </v-subheader>
          <v-divider />
          <v-list-item-group>
            <v-list-item
              v-for="(pinblock, index) in pinBlock"
              :key="index"
              :style="{ backgroundColor: rowColor(index) }"
              dense
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-row dense align="center">
                    <v-col cols="12" md="4">
                      {{ pinblock.number }}
                    </v-col>
                    <v-col cols="12" md="4">
                      {{ pinblock.name }}
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        dense
                        :items="testpoints"
                        label=""
                        item-text="refNet"
                        item-value="net"
                        v-model="pinblock.net"
                        hide-details
                        single-line
                        class="caption"
                        auto-select-first
                        @change="updateEdited(pinblock)"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="ma-2">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn color="warning" class="mx-auto" @click="onCancel">{{
              saveEnabled ? "Cancel" : "Close"
            }}</v-btn>
            <v-spacer />
            <v-btn
              :disabled="!saveEnabled"
              color="primary"
              class="mx-auto"
              @click="onSave"
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <base-bottom-dialog
      :dialog="baseDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @delete="deleteHandler"
      @donothing="stopBaseDialog"
    />
  </v-dialog>
</template>
<script>
export default {
  name: "PinBlockModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    pinBlock: {
      type: Array,
      default: null,
    },
    block: {
      type: Object,
      default: null,
    },
    testpoints: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      pinGrid: null,
      rules: {
        requireCheck: [(v) => !!v || this.override || "Input is required"],
      },
      baseDialog: false,
      confirmMessage: null,
      confirmAction: null,
    };
  },
  computed: {
    saveEnabled() {
      const edited = this.pinBlock
        ? this.pinBlock.filter((element) => element.edited)
        : [];
      return edited.length > 0;
    },
  },
  methods: {
    onClose() {
      this.$emit("closeModal");
    },
    onCancel() {
      this.pinGrid = null;
      this.onClose();
    },
    onSave() {
      this.$emit("saveData", [...this.pinBlock]);
      this.onClose();
    },
    rowColor(index) {
      return index % 2 !== 0 ? "#F5F5F5" : "white";
    },
    updateEdited(pin) {
      const index = this.pinBlock.indexOf(pin);
      this.pinBlock[index].edited = true;
    },
    stopBaseDialog() {
      this.baseDialog = false;
    },
    showDeleteDialog(nextAction) {
      this.confirmAction = nextAction;
      this.confirmMessage = `Are you sure to ${nextAction} connector block no. ${this.block.pk} (${this.block.ref})?`;
      this.baseDialog = true;
    },
    deleteHandler() {
      this.stopBaseDialog();
      this.$emit("deleteBlock", this.block.pk);
      this.onClose();
    },
  },
};
</script>